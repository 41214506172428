import styled from "styled-components"

const Styles = styled.div`
  display: flex;
  gap: 0.5rem;
  margin: 1rem 0;
  width: 100%;

  & > div {
    width: 100%;
  }

  .profile-pic {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
  }

  .profile-pic-small {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }
`

export default Styles
