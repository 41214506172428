import React from "react"
import Styles from "./style"
import fotoTemp from "../../../../images/profile_page/icon-akun.png"
import moment from "moment"
import { HiBadgeCheck } from "@react-icons/all-files/hi/HiBadgeCheck"
import { deleteCourseCommentReply } from "../../../../services/course"
import { BsTrash } from "@react-icons/all-files/bs/BsTrash"
import { toast } from "react-toastify"

const KomentarBalasan = ({ balasan, tutorId, user, removeReply }) => {
  const deleteBalasan = async () => {
    const resp = await deleteCourseCommentReply(balasan.id)
    if (resp?.status === 204) {
      removeReply(balasan.id)
      toast.success("Balasan berhasil dihapus")
    } else {
      toast.error("Terjadi kesalahan")
    }
  }

  return (
    <Styles>
      <img
        src={balasan.user.profile_picture || fotoTemp}
        alt={balasan.user.username}
        className="profile-pic-small"
      />
      <div className="comment-container">
        <div className="comment-head">
          <div
            className={`comment-name ${balasan.user.id === tutorId && "tutor"}`}
          >
            <div>{balasan.user.display_name || balasan.user.username} </div>
            {balasan.user.id === tutorId && <HiBadgeCheck />}
          </div>
          <div className="comment-date">
            {moment(balasan.created_at).fromNow()}
          </div>
        </div>

        <div className="content">{balasan.content}</div>

        {balasan?.user?.id === user?.id && (
          <button
            className="balas"
            onClick={() => {
              deleteBalasan()
            }}
          >
            <BsTrash /> hapus
          </button>
        )}
      </div>
    </Styles>
  )
}

export default KomentarBalasan
