import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CourseVideoComponent from "../components/CourseVideoComponent"
import { getCourseById } from "../services/course"
import { getParameterByName } from "../services/utils"
import * as sessionSelectors from "../redux/slices/session/selectors"
import Spinner from "../components/Reusable/Spinner"

const mapStateToProps = state => ({
  user: sessionSelectors.getUser(state),
})

const mapDispatchToProps = {}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const CourseVideoPage = ({ user }) => {
  const [id, setId] = useState(-1)
  const [vid, setVid] = useState(-1)
  const [course, setCourse] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setId(getParameterByName("id"))
    setVid(getParameterByName("v"))
  }, [typeof window !== `undefined` && window.location.href])

  useEffect(() => {
    if (id >= 0 && vid >= 0) {
      getCourseById(id).then(resp => {
        setCourse(resp.data)
        setLoading(false)
      })
    }
  }, [id, vid, user])

  return (
    <Layout>
      <SEO title={course?.title || ""} />
      {!loading ? (
        <CourseVideoComponent course={course} itemId={vid} user={user} />
      ) : (
        <div
          style={{
            width: "100%",
            height: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}
    </Layout>
  )
}

export default withConnect(CourseVideoPage)
