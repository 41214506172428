import React, { useState } from "react"
import TextArea from "../../../Reusable/Input/textarea"
import Button from "../../../Reusable/Button"
import Styles from "./styles"
import { submitCourseCommentReply } from "../../../../services/course"
import { toast } from "react-toastify"
import fotoTemp from "../../../../images/profile_page/icon-akun.png"

const TulisKomentarBalasan = ({
  commentId,
  setActive,
  user,
  updateReplies,
}) => {
  const [inputField, setInputField] = useState({
    isi: "",
  })
  const [loading, setLoading] = useState(false)

  const inputsHandler = e => {
    setInputField({ ...inputField, [e.target.name]: e.target.value })
  }

  const submitKomentar = async () => {
    setLoading(true)
    const resp = await submitCourseCommentReply({
      user: user.id,
      content: inputField.isi,
      comment: commentId,
    })
    if (resp?.status === 201) {
      toast.success("Berhasil menambahkan komentar balasan")
      updateReplies()
    } else {
      toast.error("Terjadi kesalahan")
    }
    setInputField({ isi: "" })
    setLoading(false)
  }

  const batal = () => {
    setActive(false)
    setInputField({ isi: "" })
  }

  return (
    <Styles>
      <img
        src={user.profile_picture || fotoTemp}
        alt={user.username}
        className="profile-pic-small"
      />
      <div>
        <TextArea
          name="isi"
          placeholder="Tuliskan balasan"
          label=""
          value={inputField.isi}
          onChange={inputsHandler}
          rows={2}
        />
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <Button
            onClick={submitKomentar}
            disabled={!inputField.isi}
            className="small"
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
          <Button onClick={batal} className="secondary small">
            Batal
          </Button>
        </div>
      </div>
    </Styles>
  )
}

export default TulisKomentarBalasan
