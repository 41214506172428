import React, { useState, useEffect, useRef } from "react"
import KomentarBox from "./KomentarBox"
import TulisKomentar from "./TulisKomentar"
import { getCourseComments } from "../../../services/course"
import styled from "styled-components"
import useOnScreen from "../../Hooks/useIntersection"
import axios from "axios"
import Spinner from "../../Reusable/Spinner"

const Styled = styled.div`
  padding-top: 1rem;

  .notices {
    font-size: 0.875rem;
    padding: 1rem;
    border-radius: 4px;
    background: #fef9c3;
  }

  details > summary {
    list-style: none;
  }

  details > summary::-webkit-details-marker {
    display: none;
  }

  summary > span {
    color: blue;
  }
`

const DiskusiSection = ({ refId, tutorId, user }) => {
  const [comments, setComments] = useState([])
  const [next, setNext] = useState(false)
  const [loading, setLoading] = useState(false)
  const elementRef = useRef(null)
  const isOnScreen = useOnScreen(elementRef)

  useEffect(() => {
    if (refId > 0) {
      setLoading(true)
      getCourseComments({ refId }).then(resp => {
        setComments([...comments, ...resp?.data?.results])
        setNext(resp.data?.next)
        setLoading(false)
      })
    }
  }, [])

  useEffect(() => {
    if (isOnScreen && next) {
      setLoading(true)
      axios.get(next).then(resp => {
        setComments([...comments, ...resp?.data?.results])
        setNext(resp.data?.next)
        setLoading(false)
      })
    }
  }, [isOnScreen])

  const updateCommentsAfterSubmit = data => {
    setComments([data, ...comments])
  }

  const removeCommentAfterDelete = id => {
    setComments(comments.filter(comment => comment.id !== id))
  }

  return (
    <Styled>
      <details className="notices">
        <summary>
          Fitur diskusi sekarang beralih jadi komentar.{" "}
          <span>Lebih lengkap</span>
        </summary>
        <p style={{ paddingTop: "1rem" }}>
          Untuk meningkatkan pengalaman pengguna kedepannya, fitur diskusi yang
          awalnya dibuat per kursus, saat ini berubah menjadi fitur komentar per
          video.
        </p>
        <p>
          Anda dapat mengirim komentar pada setiap video. Anda juga dapat
          mengirim komentar balasan dari sebuah komentar. Anda juga dapat
          menghapus setiap komentar yang telah Anda kirim.
        </p>
        <p>
          Anda tetap dapat melihat riwayat diskusi lama anda pada video pertama
          dari setiap kursus.
        </p>
        {/* <Button className="small">Saya Mengerti</Button> */}
      </details>

      <TulisKomentar
        refId={refId}
        user={user}
        updateComments={updateCommentsAfterSubmit}
      />
      <hr />

      {comments.length > 0
        ? comments.map((comment, index) => (
            <KomentarBox
              comment={comment}
              tutorId={tutorId}
              key={index}
              user={user}
              removeAfterDelete={removeCommentAfterDelete}
            />
          ))
        : "Belum ada komentar"}

      {loading && (
        <div
          style={{
            width: "100%",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div>
      )}

      <div ref={elementRef}></div>
    </Styled>
  )
}

export default DiskusiSection
