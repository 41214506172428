import React, { useState, useEffect } from "react"
import Styles from "./style"
import fotoTemp from "../../../../images/profile_page/icon-akun.png"
import moment from "moment"
import { HiBadgeCheck } from "@react-icons/all-files/hi/HiBadgeCheck"
import { BsReply } from "@react-icons/all-files/bs/BsReply"
import { BsChevronDown } from "@react-icons/all-files/bs/BsChevronDown"
import { BsChevronUp } from "@react-icons/all-files/bs/BsChevronUp"
import { BsTrash } from "@react-icons/all-files/bs/BsTrash"
import TulisKomentarBalasan from "../TulisKomentar/balasan"
import {
  deleteCourseComment,
  getCourseCommentReplies,
} from "../../../../services/course"
import KomentarBalasan from "./balasan"
import { toast } from "react-toastify"

const KomentarBox = ({ comment, tutorId, user, removeAfterDelete }) => {
  const [active, setActive] = useState(false)
  const [showReplies, setShowReplies] = useState(false)
  const [replies, setReplies] = useState([])

  useEffect(() => {
    updateReplies()
  }, [comment, tutorId])

  const updateReplies = async () => {
    setActive(false)
    const resp = await getCourseCommentReplies(comment?.id)
    if (resp?.data?.results) setReplies(resp?.data?.results)
  }

  const deleteComment = async commentId => {
    const resp = await deleteCourseComment(commentId)
    if (resp?.status === 204) {
      removeAfterDelete(comment.id)
      toast.success("Komentar berhasil dihapus")
    } else {
      toast.error("Terjadi kesalahan")
    }
  }

  const removeReply = id => {
    setReplies(replies.filter(reply => reply.id !== id))
  }

  return (
    <Styles>
      <img
        src={comment.user.profile_picture || fotoTemp}
        alt={comment.user.username}
        className="profile-pic"
      />
      <div className="comment-container">
        <div className="comment-head">
          <div
            className={`comment-name ${comment.user.id === tutorId && "tutor"}`}
          >
            <div>{comment.user.display_name || comment.user.username} </div>
            {comment.user.id === tutorId && <HiBadgeCheck />}
          </div>

          <div className="comment-date">
            {moment(comment.created_at).fromNow()}
          </div>
        </div>

        <div className="content">{comment.content}</div>

        <button
          className="balas"
          style={{
            display: replies?.length > 0 ? "inline-block" : "none",
          }}
          onClick={() => setShowReplies(!showReplies)}
        >
          {showReplies ? <BsChevronUp /> : <BsChevronDown />}{" "}
          {showReplies ? "sembunyikan" : "lihat"} {replies?.length} balasan
        </button>

        <button className="balas" onClick={() => setActive(!active)}>
          <BsReply /> balas
        </button>

        {comment?.user?.id === user?.id && (
          <button
            className="balas"
            onClick={() => {
              deleteComment(comment?.id)
            }}
          >
            <BsTrash /> hapus
          </button>
        )}

        {active && (
          <TulisKomentarBalasan
            setActive={setActive}
            user={user}
            commentId={comment?.id}
            updateReplies={updateReplies}
          />
        )}

        {showReplies &&
          replies.map((reply, index) => (
            <KomentarBalasan
              balasan={reply}
              tutorId={tutorId}
              key={index}
              user={user}
              removeReply={removeReply}
            />
          ))}
      </div>
    </Styles>
  )
}

export default KomentarBox
