import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import TutorProfile from "../CourseComponent/tutor-profile"
import MyKelasList from "../CourseComponent/kelas-list"
import DiskusiSection from "./Diskusi"
import CourseProgress from "./progress"
import { getCourseProgress } from "../../services/course"
import NativeSelect from "@material-ui/core/NativeSelect"
import VideoPlayer from "../Reusable/VideoPlayer"
import { CourseVideoStyles, StyledCourseDescWrap } from "./style"

const CourseVideoComponent = ({ course, itemId, user }) => {
  const [currentVideo, setCurrentVideo] = useState({})
  const [commentRefId, setCommentRefId] = useState(-1)
  const [activeTab, setActiveTab] = useState("0")

  useEffect(() => {
    if (course && course.is_owned === false) {
      navigate(`/course?id=${course.id}`)
    }
    setCurrentVideo(getVideo())
  }, [course, itemId])

  const getVideo = () => {
    if (course?.chapters)
      for (const chapter of course.chapters) {
        if (chapter?.items)
          for (const item of chapter.items) {
            if (item?.id === parseInt(itemId)) {
              setCommentRefId(parseInt(item.id))
              return item.video
            }
          }
      }
    setCommentRefId(0)
    return {}
  }

  const [progress, setProgress] = useState(-1)

  useEffect(() => {
    getCourseProgress(course.id).then(resp =>
      setProgress(resp.data?.percentage)
    )
  }, [])

  const handleTabChange = e => {
    setActiveTab(e.target.value)
  }

  return (
    <CourseVideoStyles className="layout">
      <div className="course-vid-wrapper">
        <div className="course-vid-left">
          {currentVideo && (
            <VideoPlayer key={currentVideo.id} source={currentVideo.file} />
          )}
          <div className="judul-vid">
            <h4>{currentVideo.title}</h4>
            <p>{currentVideo.description}</p>
          </div>

          <div className="mobile-only">
            <NativeSelect value={activeTab} onChange={handleTabChange}>
              <option value="0">Komentar</option>
              <option value="1">Detail Kursus</option>
            </NativeSelect>
          </div>

          {activeTab === "0" && (
            <DiskusiSection
              refId={commentRefId}
              tutorId={course?.tutor?.id}
              user={user}
              key={commentRefId}
            />
          )}
          {activeTab === "1" && (
            <div>
              <h4 className="judul-content-right course-desc-wrap">
                {course.title}
              </h4>

              {progress >= 0 && (
                <StyledCourseDescWrap>
                  <CourseProgress progress={progress} courseId={course.id} />
                </StyledCourseDescWrap>
              )}

              <div className="class-overview course-desc-wrap">
                <div className="daftar-isi">
                  <div>
                    <MyKelasList
                      chapters={course.chapters}
                      courseId={course.id}
                      beli={course.is_owned}
                      chosen={parseInt(itemId)}
                    />
                  </div>
                </div>
              </div>

              <div className="tutor-summary course-desc-wrap">
                {course.tutor && <TutorProfile tutor={course?.tutor} />}
              </div>
            </div>
          )}
        </div>

        <div className="course-vid-right">
          <h4 className="judul-content-right course-desc-wrap">
            {course.title}
          </h4>

          {progress >= 0 && (
            <div className="course-desc-wrap">
              <CourseProgress progress={progress} courseId={course.id} />
            </div>
          )}

          <div className="class-overview">
            <div className="daftar-isi">
              <div>
                <MyKelasList
                  chapters={course.chapters}
                  courseId={course.id}
                  beli={course.is_owned}
                  chosen={parseInt(itemId)}
                />
              </div>
            </div>
          </div>

          <div className="tutor-summary course-desc-wrap">
            {course.tutor && <TutorProfile tutor={course?.tutor} />}
          </div>
        </div>
      </div>
    </CourseVideoStyles>
  )
}

export default CourseVideoComponent
