import React, { useState } from "react"
import TextArea from "../../../Reusable/Input/textarea"
import Button from "../../../Reusable/Button"
import { submitCourseComment } from "../../../../services/course"
import { toast } from "react-toastify"
import Styles from "./styles"
import fotoTemp from "../../../../images/profile_page/icon-akun.png"

const TulisKomentar = ({ refId, user, updateComments }) => {
  const [inputField, setInputField] = useState({
    isi: "",
  })
  const [focused, setFocused] = useState(false)
  const [loading, setLoading] = useState(false)

  const onFocus = () => setFocused(true)
  const onBlur = () => {
    !inputField.isi && setFocused(false)
  }

  const inputsHandler = e => {
    setInputField({ ...inputField, [e.target.name]: e.target.value })
  }

  const submitKomentar = async () => {
    setLoading(true)
    const resp = await submitCourseComment({
      content: inputField.isi,
      reference: refId,
    })
    if (resp?.data?.id) {
      toast.success("Komentar berhasil ditambahkan")
      setLoading(false)
      setInputField({ isi: "" })
      updateComments(resp?.data)
      setFocused(false)
    } else {
      toast.error("Gagal mensubmit komentar")
    }
  }

  const batal = () => {
    setInputField({ isi: "" })
    setFocused(false)
  }

  return (
    <Styles>
      <img
        src={user.profile_picture || fotoTemp}
        alt={user.username}
        className="profile-pic"
      />
      <div>
        <TextArea
          name="isi"
          placeholder="Tuliskan Komentar"
          label=""
          value={inputField.isi}
          onChange={inputsHandler}
          rows={focused ? 5 : 2}
          onFocus={onFocus}
          onBlur={onBlur}
        />
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <Button
            onClick={submitKomentar}
            disabled={!inputField.isi}
            style={{ display: !focused && "none" }}
          >
            {loading ? "Submitting..." : "Submit"}
          </Button>
          <Button
            onClick={batal}
            className="secondary"
            style={{ display: !focused && "none" }}
          >
            Batal
          </Button>
        </div>
      </div>
    </Styles>
  )
}

export default TulisKomentar
