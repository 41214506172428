import styled from "styled-components"

const Styles = styled.div`
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  display: flex;
  gap: 1rem;
  width: 100%;

  .comment-container {
    width: 100%;
  }

  .profile-pic {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
  }

  .profile-pic-small {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }

  .comment-head {
    display: flex;
    gap: 0.5rem;
    padding-top: 2px;
    font-size: 0.875rem;

    .comment-name {
      font-weight: 600;
    }

    .tutor {
      font-size: 0.75rem;
      background: #efa51d;
      border-radius: 20px;
      color: white;
      font-weight: 500;
      padding-left: 0.5rem;
      padding-right: 0.25rem;
      display: flex;
      vertical-align: middle;
    }

    .tutor > div {
      margin-top: 2px;
    }

    .tutor > svg {
      margin-left: 2px;
      padding-top: 3px;
      font-size: 1rem;
    }

    .comment-date {
      padding-top: 2px;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1rem;
    }
  }

  .content {
    padding: 0.5rem 0;
  }

  .balas {
    margin-left: 1rem;
    font-size: 0.875rem;
    color: #6b7280;
    font-weight: 500;
    border: none;
    background: white;
    margin-bottom: 1rem;
  }
`

export default Styles
