import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Image from "../image"
import { getCoursesByTutor } from "../../services/course"
import fotoTemp from "../../images/profile_page/icon-akun.png"

export const Styles = styled.div`
  &.tutor-instructor {
    padding-top: 12px;
    padding-left: 15px;
    padding-right: 15px;

    & > a {
      color: black;
      display: block;
    }

    & > a:hover {
      opacity: 0.8;
      text-decoration: none;
    }

    h5 {
      font-weight: 500;
    }

    img {
      width: 100%;
      max-width: 180px;
      border-radius: 50%;

      margin-right: 25px;
    }

    #tutor-name {
      margin-bottom: 20px;
    }

    .tutor-info {
      display: flex;

      .info-col {
        display: flex;
        flex-wrap: wrap;
        flex-flow: column wrap;

        margin-bottom: 15px;
        height: 100%;
        padding-left: 10px;

        .info-row {
          display: flex;
          margin-bottom: 2px;
          margin-top: 2px;
          align-items: center;

          .info-wrapper {
            margin-left: 15px;
            width: calc(100% - 30px);
          }

          .icon-wrapper {
            width: 30px;
            margin: 5px 0;
          }

          span {
            font-weight: 300;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 767.98px) {
    &.tutor-instructor {
      text-align: center;

      img {
        margin-right: 0 !important;
      }

      .tutor-info {
        flex-flow: column wrap;

        .info-col {
          padding-left: 0px;
          margin-top: 15px;
          align-items: center;

          .info-row {
            text-align: left;
          }
        }
      }
    }
  }
`

export default function TutorProfile({ tutor }) {
  const [numberCourses, setNumberCourses] = useState(0)
  const course = {}
  const reviews = {}

  useEffect(() => {
    getCoursesByTutor(tutor.id).then(resp => {
      const data = resp.data?.results
      setNumberCourses(data.length)
    })
  }, [])

  return (
    <Styles className="tutor-instructor">
      <Link to={`/${tutor?.tutor_profile?.slug}/`}>
        <div>
          <h5 id="tutor-name">{tutor.display_name || tutor.username}</h5>
        </div>
        <div className="tutor-info">
          <div>
            <img
              src={tutor.profile_picture || fotoTemp}
              alt={tutor.display_name || tutor.username}
            />
          </div>
          <div className="info-col">
            <div>
              <div className="info-row">
                <div className="icon-wrapper">
                  <Image imgName="icon-email.png" />
                </div>
                <div className="info-wrapper">
                  <span>{course.rate} Course Ratings</span>
                </div>
              </div>
            </div>
            <div>
              <div className="info-row">
                <div className="icon-wrapper">
                  <Image imgName="icon-telepon.png" />
                </div>
                <div className="info-wrapper">
                  <span>{reviews?.length} Course Reviews</span>
                </div>
              </div>
            </div>
            <div>
              <div className="info-row">
                <div className="icon-wrapper">
                  <Image imgName="icon-email.png" />
                </div>
                <div className="info-wrapper">
                  <span>{""} Participants</span>
                </div>
              </div>
            </div>
            <div>
              <div className="info-row">
                <div className="icon-wrapper">
                  <Image imgName="icon-youtube.png" />
                </div>
                <div className="info-wrapper">
                  <span>{numberCourses} Courses</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p>{tutor?.length > 0 ? tutor[0].bio : ""}</p>
      </Link>
    </Styles>
  )
}
